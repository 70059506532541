/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #171717;
  margin: 0px;
  padding: 0px;
  background-color: #EAEAEA;
}

.navbar {
  position: sticky; top: 0;
  background-color:#EAEAEA;
  z-index: 1;
  /* border-bottom: 1px solid #eeeeee */
}

.image-container {
  width:108px;;
}

.top {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  /* height: 144px; */
}

.nav {
  display: flex;
  flex-direction: row;
}

.nav a{
  margin: 10px;
}

a {
  color: #000;
  text-decoration: none;
}
a:hover {
  text-decoration:underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 8px;
  color: #73202C;
}

.home{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
}

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 970px;
  margin-top: 20px;
}

.column {
  width: 310px;
}

.column > a {
  text-decoration: underline;
  color: #73202C;
}

.column > a:hover {
  /* text-decoration: underline; */
  text-underline-offset: 1px;
}

.main-list {
  padding-left: 15px;
}

.providers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* display: flex; */
  /* flex-direction: row; */
}

/* .footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.footer {
  /* display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 970px; */
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  justify-content: space-between;
  width: 970px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 20px;
  /* height: 144px; */
}

.footer-links > a:hover{
  text-decoration: none;
  color: #000;
}

.copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:max-content;
}

.slide-container {
  width: 950px;
  margin: auto;
  z-index: -1;
  position: relative; top: 20px;
  box-shadow: 7px 7px 7px 0px rgba(0,0,0,0.3);
}

.slide-background {
  width: 100%;
  height: 380px;
  background-color:#22222a;
  z-index: 0;
}

.providers > h3 {
  text-align: center;
}

.each-fade {
  display: flex;
  width: 100%;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
}

.each-fade > div {
  width: 100%;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
}

.each-fade > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.3);
}

/* .each-fade h2 {
  width: 25%;
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #EAEAEA;
} */

.about {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.content-left {
  margin-right: 80px;
  justify-content: left;
}

.services {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.careers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.td {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.resources {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}